.container {
    max-width: 600px;
    margin: 50px auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .label {
    display: block;
    margin-bottom: 8px;
  }
  
  .input {
    width: 100%;
    padding: 8px;
    margin-bottom: 16px;
  }
  
  .questionContainer {
    margin-bottom: 16px;
  }
  
  .answerInput {
    width: 100%;
    padding: 8px;
    margin-top: 8px;
  }
  
  .button {
    background-color: #4caf50;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }