body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background: linear-gradient(0deg, #ffffff 78%, #82b7eb3a 78%) no-repeat !important; */
  padding: 5px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.newchat{
  background-image: linear-gradient(to right top, #d0a8bf, #cbacc7, #c4b0ce, #beb5d3, #b7b9d7, #b3bedb, #afc4de, #adc9e0, #acd1e3, #acd8e4, #b0dfe4, #b6e5e2);
  
}
.newchat:hover{
  animation: pulse 0.2s;
}


@keyframes pulse {
  0%,
  100% {
    animation-timing-function: ease-in;
  }
  50% {
    transform: scale(1.1);
  }
}
